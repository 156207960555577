/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dark:hover,
.ant-btn-dark:focus {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.75);
  border-color: rgba(52, 58, 64, 0.1);
}
.ant-btn-dark:active,
.ant-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}
.ant-btn-background-ghost.ant-btn-dark {
  color: #343a40;
  background: transparent;
  border-color: #343a40;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
  color: rgba(52, 58, 64, 0.75);
  background: transparent;
  border-color: rgba(52, 58, 64, 0.75);
}
.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
  color: #343a40;
  background: transparent;
  border-color: #1d2124;
}
.ant-btn-lightdark {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.75);
  border-color: rgba(108, 117, 125, 0.1);
}
.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.ant-btn-background-ghost.ant-btn-lightdark {
  color: #6c757d;
  background: transparent;
  border-color: #6c757d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
  color: rgba(108, 117, 125, 0.75);
  background: transparent;
  border-color: rgba(108, 117, 125, 0.75);
}
.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
  color: #6c757d;
  background: transparent;
  border-color: #545b62;
}
.ant-btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 0.1);
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745;
  background: transparent;
  border-color: #28a745;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: rgba(40, 167, 69, 0.75);
  background: transparent;
  border-color: rgba(40, 167, 69, 0.75);
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745;
  background: transparent;
  border-color: #1e7e34;
}
.ant-btn-warning {
  color: #fff;
  background-color: #eca52b;
  border-color: #eca52b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: rgba(236, 165, 43, 0.75);
  border-color: rgba(236, 165, 43, 0.1);
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff;
  background-color: #d18b13;
  border-color: #d18b13;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b;
  background: transparent;
  border-color: #eca52b;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: rgba(236, 165, 43, 0.75);
  background: transparent;
  border-color: rgba(236, 165, 43, 0.75);
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b;
  background: transparent;
  border-color: #d18b13;
}
.ant-btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: rgba(23, 162, 184, 0.75);
  border-color: rgba(23, 162, 184, 0.1);
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #117a8b;
  border-color: #117a8b;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8;
  background: transparent;
  border-color: #17a2b8;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: rgba(23, 162, 184, 0.75);
  background: transparent;
  border-color: rgba(23, 162, 184, 0.75);
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8;
  background: transparent;
  border-color: #117a8b;
}
.disable-animation:after {
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-typography blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.ant-typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.ant-typography blockquote p {
  display: inline;
}
:root {
  --primary-color: #002e7d;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  background: #002e7d !important;
}
.ant-upload-list-picture-card {
  margin-top: 8px;
}
.ant-form-item-label {
  white-space: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-Italic-VariableFont_wght-subset.woff2")
    format("woff2");
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
  unicode-range: U+20-7E, U+C4, U+C5, U+D6, U+E4, U+E5, U+F6;
}

@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght-subset.woff2") format("woff2");
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-7E, U+C4, U+C5, U+D6, U+E4, U+E5, U+F6;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.rcp-light {
  --rcp-background: #ffffff;
  --rcp-input-text: #111111;
  --rcp-input-border: rgba(0, 0, 0, 0.1);
  --rcp-input-label: #717171;
}

.rcp-dark {
  --rcp-background: #181818;
  --rcp-input-text: #f3f3f3;
  --rcp-input-border: rgba(255, 255, 255, 0.1);
  --rcp-input-label: #999999;
}

.rcp {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--rcp-background);
  border-radius: 10px;
}

.rcp-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  box-sizing: border-box;

  padding: 20px;
}

.rcp-saturation {
  position: relative;

  width: 100%;
  background-image: linear-gradient(transparent, black), linear-gradient(to right, white, transparent);
  border-radius: 10px 10px 0 0;
  
  -webkit-user-select: none;
  
     -moz-user-select: none;
  
      -ms-user-select: none;
  
          user-select: none;
}

.rcp-saturation-cursor {
  position: absolute;

  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;

  transform: translate(-10px, -10px);
}

.rcp-hue {
  position: relative;

  width: 100%;
  height: 12px;
  
  background-image: linear-gradient(
    to right,
    rgb(255, 0, 0),
    rgb(255, 255, 0),
    rgb(0, 255, 0),
    rgb(0, 255, 255),
    rgb(0, 0, 255),
    rgb(255, 0, 255),
    rgb(255, 0, 0)
  );
  border-radius: 10px;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none
}

.rcp-hue-cursor {
  position: absolute;

  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
  box-sizing: border-box;

  transform: translate(-10px, -4px);
}

.rcp-alpha {
  position: relative;

  width: 100%;
  height: 12px;

  border-radius: 10px;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.rcp-alpha-cursor {
  position: absolute;

  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
  box-sizing: border-box;

  transform: translate(-10px, -4px);
}

.rcp-fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  gap: 10px;

  width: 100%;
}

.rcp-fields-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  width: 100%;
}

.hex-element {
  grid-row: 1;
}

.hex-element:nth-child(3n) {
  grid-column: 1 / -1;
}

.rcp-fields-element-input {
  width: 100%;
  
  font-size: 14px;
  font-weight: 600;

  color: var(--rcp-input-text);
  text-align: center;

  background: none;
  border: 2px solid;
  border-color: var(--rcp-input-border);
  border-radius: 5px;
  box-sizing: border-box;
  
  outline: none;

  padding: 10px;
}

.rcp-fields-element-label {
  font-size: 14px;
  font-weight: 600;

  color: var(--rcp-input-label);
  text-transform: uppercase;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dark:hover,
.ant-btn-dark:focus {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.75);
  border-color: rgba(52, 58, 64, 0.1);
}
.ant-btn-dark:active,
.ant-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}
.ant-btn-background-ghost.ant-btn-dark {
  color: #343a40;
  background: transparent;
  border-color: #343a40;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
  color: rgba(52, 58, 64, 0.75);
  background: transparent;
  border-color: rgba(52, 58, 64, 0.75);
}
.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
  color: #343a40;
  background: transparent;
  border-color: #1d2124;
}
.ant-btn-lightdark {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.75);
  border-color: rgba(108, 117, 125, 0.1);
}
.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.ant-btn-background-ghost.ant-btn-lightdark {
  color: #6c757d;
  background: transparent;
  border-color: #6c757d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
  color: rgba(108, 117, 125, 0.75);
  background: transparent;
  border-color: rgba(108, 117, 125, 0.75);
}
.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
  color: #6c757d;
  background: transparent;
  border-color: #545b62;
}
.ant-btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 0.1);
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745;
  background: transparent;
  border-color: #28a745;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: rgba(40, 167, 69, 0.75);
  background: transparent;
  border-color: rgba(40, 167, 69, 0.75);
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745;
  background: transparent;
  border-color: #1e7e34;
}
.ant-btn-warning {
  color: #fff;
  background-color: #eca52b;
  border-color: #eca52b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: rgba(236, 165, 43, 0.75);
  border-color: rgba(236, 165, 43, 0.1);
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff;
  background-color: #d18b13;
  border-color: #d18b13;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b;
  background: transparent;
  border-color: #eca52b;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: rgba(236, 165, 43, 0.75);
  background: transparent;
  border-color: rgba(236, 165, 43, 0.75);
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b;
  background: transparent;
  border-color: #d18b13;
}
.ant-btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: rgba(23, 162, 184, 0.75);
  border-color: rgba(23, 162, 184, 0.1);
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #117a8b;
  border-color: #117a8b;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8;
  background: transparent;
  border-color: #17a2b8;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: rgba(23, 162, 184, 0.75);
  background: transparent;
  border-color: rgba(23, 162, 184, 0.75);
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8;
  background: transparent;
  border-color: #117a8b;
}
.disable-animation:after {
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-typography blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.ant-typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.ant-typography blockquote p {
  display: inline;
}
:root {
  --primary-color: #002e7d;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  background: #002e7d !important;
}
.ant-upload-list-picture-card {
  margin-top: 8px;
}
.ant-form-item-label {
  white-space: normal;
}
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-family: "Raleway";
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #f5faff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #002e7d;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #16448a;
}
a:active {
  color: #001d57;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #002e7d;
}
::selection {
  color: #fff;
  background: #002e7d;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.ant-fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #002e7d;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #002e7d;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #002e7d;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #002e7d;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dark:hover,
.ant-btn-dark:focus {
  color: #fff;
  background-color: rgba(52, 58, 64, 0.75);
  border-color: rgba(52, 58, 64, 0.1);
}
.ant-btn-dark:active,
.ant-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}
.ant-btn-background-ghost.ant-btn-dark {
  color: #343a40;
  background: transparent;
  border-color: #343a40;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
  color: rgba(52, 58, 64, 0.75);
  background: transparent;
  border-color: rgba(52, 58, 64, 0.75);
}
.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
  color: #343a40;
  background: transparent;
  border-color: #1d2124;
}
.ant-btn-lightdark {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.75);
  border-color: rgba(108, 117, 125, 0.1);
}
.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.ant-btn-background-ghost.ant-btn-lightdark {
  color: #6c757d;
  background: transparent;
  border-color: #6c757d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
  color: rgba(108, 117, 125, 0.75);
  background: transparent;
  border-color: rgba(108, 117, 125, 0.75);
}
.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
  color: #6c757d;
  background: transparent;
  border-color: #545b62;
}
.ant-btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 0.1);
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.ant-btn-background-ghost.ant-btn-success {
  color: #28a745;
  background: transparent;
  border-color: #28a745;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
  color: rgba(40, 167, 69, 0.75);
  background: transparent;
  border-color: rgba(40, 167, 69, 0.75);
}
.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
  color: #28a745;
  background: transparent;
  border-color: #1e7e34;
}
.ant-btn-warning {
  color: #fff;
  background-color: #eca52b;
  border-color: #eca52b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: rgba(236, 165, 43, 0.75);
  border-color: rgba(236, 165, 43, 0.1);
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: #fff;
  background-color: #d18b13;
  border-color: #d18b13;
}
.ant-btn-background-ghost.ant-btn-warning {
  color: #eca52b;
  background: transparent;
  border-color: #eca52b;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
  color: rgba(236, 165, 43, 0.75);
  background: transparent;
  border-color: rgba(236, 165, 43, 0.75);
}
.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
  color: #eca52b;
  background: transparent;
  border-color: #d18b13;
}
.ant-btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-info:hover,
.ant-btn-info:focus {
  color: #fff;
  background-color: rgba(23, 162, 184, 0.75);
  border-color: rgba(23, 162, 184, 0.1);
}
.ant-btn-info:active,
.ant-btn-info.active {
  color: #fff;
  background-color: #117a8b;
  border-color: #117a8b;
}
.ant-btn-background-ghost.ant-btn-info {
  color: #17a2b8;
  background: transparent;
  border-color: #17a2b8;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
  color: rgba(23, 162, 184, 0.75);
  background: transparent;
  border-color: rgba(23, 162, 184, 0.75);
}
.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
  color: #17a2b8;
  background: transparent;
  border-color: #117a8b;
}
.disable-animation:after {
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-typography blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}
.ant-typography blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.ant-typography blockquote p {
  display: inline;
}
:root {
  --primary-color: #002e7d;
}
.ant-layout-sider-zero-width-trigger-left {
  top: 0px !important;
  background: #002e7d !important;
}
.ant-upload-list-picture-card {
  margin-top: 8px;
}
.ant-form-item-label {
  white-space: normal;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #0a0a0a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #28a745;
}
.anticon.ant-notification-notice-icon-info {
  color: #17a2b8;
}
.anticon.ant-notification-notice-icon-warning {
  color: #eca52b;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationTopFadeIn;
          animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationBottomFadeIn;
          animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
@-webkit-keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

