@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-Italic-VariableFont_wght-subset.woff2")
    format("woff2");
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
  unicode-range: U+20-7E, U+C4, U+C5, U+D6, U+E4, U+E5, U+F6;
}

@font-face {
  font-family: "Raleway";
  src: url("/fonts/Raleway-VariableFont_wght-subset.woff2") format("woff2");
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-7E, U+C4, U+C5, U+D6, U+E4, U+E5, U+F6;
}
